import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@app-env/environment'
import { ErrorService, ErrorType } from '@app/services/error/error.service'
import { UnattendendCheckInService } from '@app/services/unattendend-check-in/unattendend-check-in.service'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import { BehaviorSubject, exhaustMap, firstValueFrom, interval, map, takeWhile, tap } from 'rxjs'
import { CheckInPermissionStatusEnum } from '../../../graphql/generated'

type WebCamInfo = {
    cameraIndex: number
    name: string
    format: string
    performance: number
    index: number
}

type FacematchResults = {
    className: string
    matchResult: {
        className: string
        asString: string
        asInt: number
    }
    score: number
    expectedFAR: number
    expectedFRR: number
}

@Injectable({
    providedIn: 'root',
})
export class FaceMatchService {
    private qualityHint$$ = new BehaviorSubject<string>('')
    public qualityHint$ = this.qualityHint$$.asObservable()

    private webcamFrames$$ = new BehaviorSubject<string>('')
    public webcamFrames$ = this.webcamFrames$$.asObservable()

    private eventHandlers: Record<string, Function>

    private userName = 'EndeavourAdmin'
    private password = 'l8Ekt6D589kNkK'
    private host = `${environment.facialRecognition.endpoint}/owp/rest`

    private referenceImage: string

    private cameraName = 'OBSBOT Tiny 2 StreamCamera'
    private obsBotIndex: number

    private faceMatchStopped$ = new BehaviorSubject<boolean>(false)
    private hasSession: boolean

    constructor(
        private readonly errorService: ErrorService,
        private readonly unattendendCheckInService: UnattendendCheckInService,
        private readonly router: Router,
    ) {
    }

    public async init(): Promise<void> {
        this.clearCurrentWebCamFrame()
        await this.authenticate()
        await this.setLanguage()
        await this.initializeCamera()
        await this.adviseFaceMatch()
        this.setEventHandlers()
        await this.startFaceCapture()
        await this.captureFrames()
        await this.uploadReferenceImage()
        await this.listenToEvents()
    }

    private async authenticate(): Promise<void> {
        try {
            const sessionId: string = (await axios.get(`${this.host}/SessionManager/Authenticate?userName=${environment.facialRecognition.username}&passWord=${environment.facialRecognition.password}`)).data

            this.hasSession = true

            axios.interceptors.request.use(function (config) {
                config.headers.set('X-Session-Id', sessionId)
                config.headers.set('Content-Type', 'application/json')
                return config
            })

            axios.interceptors.response.use(
                (response) => response,
                async (error) => {
                    if (this.hasSession) {
                        this.hasSession = false
                        this.faceMatchStopped$.next(true)
                        await this.logoff()
                        await this.errorService.addError(ErrorType.UNDEFINED)
                    }
                    Bugsnag.notify({
                        name: 'OnError Logoff',
                        errorMessage: `${error.message} - ${error.request.responseURL}`,
                        errorClass: 'FaceMatch',
                    })
                    throw new Error(`${error.message} - ${error.request.responseURL}`)
                },
            )
        } catch (e: any) {
            Bugsnag.notify(e)
        }
    }

    private async setLanguage(): Promise<void> {
        // 19 = dutch
        // 9 = english
        await axios.post(`${this.host}/SessionManager/LanguageId`, {
            'value': 19,
        })
    }

    private async initializeCamera(): Promise<void> {
        const cameraList = (await axios.get(`${this.host}/Webcam/GetCameraList`)).data.webCams

        const obsBot: WebCamInfo = cameraList.find((cam: WebCamInfo) => {
            return cam.name === this.cameraName
        })

        this.obsBotIndex = obsBot.cameraIndex

        await axios.post(`${this.host}/Webcam/InitializeCamera`, {
            'cameraIdx': this.obsBotIndex,
        })
    }

    private async adviseFaceMatch(): Promise<void> {
        await axios.post(`${this.host}/SessionManager/Advise`, {
            'moduleName': 'Facematch',
            'eventName': '*',
            'queue': false,
            'eventFormat': 'JSON',
            'eventCallBack': '',
        })
    }

    public async setReferenceImage(base64: string): Promise<void> {
        this.referenceImage = base64.replace('data:image/jpeg;base64,', '')
    }

    private async uploadReferenceImage(): Promise<void> {
        try {
            await axios.post(`${this.host}/Facematch/ReferenceImageEx`, {
                'image': this.referenceImage,
                'encoding': 'beBase64',
                'complianceTests': '[compOnlyOneFaceVisible, compIsCompliant]',
                'clearImageOnError': false,
                'imageDescription': '',
            })
        } catch (e: any) {
            Bugsnag.notify(e)
        }
    }

    private async startFaceCapture(): Promise<void> {
        try {
            await axios.post(`${this.host}/Facematch/StartFaceCapture`, {
                'cameraIdx': this.obsBotIndex,
                'resetCameraWhenDone': 'nbNull',
                'sampleCount': 0,
                'timeOut': 10000,
            })
            this.faceMatchStopped$.next(false)
        } catch (e: any) {
            Bugsnag.notify(e)
        }
    }

    private setEventHandlers(): void {
        this.eventHandlers = {
            'OnClosed': async (response: any) => {
                this.faceMatchStopped$.next(true)
                if (response.value.aReason === 'TimeOut') {
                    await this.logoff()
                    await this.errorService.addError(ErrorType.IDENTITY)
                } else {
                    await this.compare()
                }
            },
        }
    }

    private async listenToEvents(): Promise<void> {
        try {
            const response = (await axios.get(`${this.host}/SessionManager/Event/5000`)).data

            if (response.eventName in this.eventHandlers) {
                await this.eventHandlers[response.eventName](response)
            }

            if (! this.faceMatchStopped$.value) {
                await this.listenToEvents()
            }
        } catch (e) {
            console.log('Error while listening to events:', e)
        }
    }

    private async captureFrames(): Promise<void> {
        interval(10).pipe(
            takeWhile(() => {
                return ! this.faceMatchStopped$.value
            }),
            exhaustMap(() => axios.get(`${this.host}/Facematch/FrameEx?width=640&height=360`)),
            map(({ data }) => data),
            tap((response) => {
                this.qualityHint$$.next(response.qualityHint.hint)
                this.webcamFrames$$.next(response.frameData)
            }),
        ).subscribe()

    }

    private async compare(): Promise<void> {
        try {
            const result: FacematchResults = (await axios.get(`${this.host}/Facematch/compare`)).data
            let hasIdentityMisMatch = false

            switch (result.matchResult.asInt) {
                case 2:
                    break
                case 3:
                    hasIdentityMisMatch = true
                    break
                default:
                    await this.errorService.addError(ErrorType.UNDEFINED)
                    return
            }

            const checkin = await firstValueFrom(this.unattendendCheckInService.finishUnattendedCheckIn({
                faceMatchResultData: result,
                id: this.unattendendCheckInService.getId(),
            }))

            switch (checkin?.permission) {
                case CheckInPermissionStatusEnum.Allowed:
                    // Opens the gate
                    await axios.post('https://hooks.nabu.casa/gAAAAABnNHPKIyO0Jd3EmXV2TRM0bo1Jm298c-xGJkAbZgtW78ScoKj-zRxBg45eAbyr0-_kcbDrh5LzXri65yVlKB_DoTTyVCbpWTEG0YcCfKuwyP3534PI2J0L95blyGzlgZQAhEAPJIBgW6fXH848EFi3qm4II4ZRM1mOkrW4hQLXjpVAQfo=')
                    await this.router.navigate(['/success'])
                    break
                case CheckInPermissionStatusEnum.Denied:
                    if (hasIdentityMisMatch) {
                        await this.errorService.addError(ErrorType.IDENTITY)
                    } else {
                        await this.errorService.addError(ErrorType.FORBIDDEN)
                    }
                    break
                default:
                    await this.errorService.addError(ErrorType.FORBIDDEN)
            }
        } catch (e: any) {
            Bugsnag.notify(e)
        } finally {
            await this.logoff()
        }
    }

    private async logoff(): Promise<void> {
        this.clearCurrentWebCamFrame()
        await axios.get(`${this.host}/SessionManager/Logoff`)

        // Clearing auth headers
        axios.interceptors.request.clear()
    }

    private clearCurrentWebCamFrame(): void {
        this.qualityHint$$.next('')
        this.webcamFrames$$.next('')
    }

}
