import { Environment, Stage } from './enviroment.types'

export const environment: Environment = {
    stage: Stage.Acc,
    api: {
        schema: 'https',
        host: 'ace-api.acceptance.kubernetes.pwstaging.tech',
        graphqlPath: 'graphql',
    },
    facialRecognition: {
        endpoint: 'https://localhost:55589',
        username: 'oribi',
        password: 'C6H8iUCyWdgtnrh',
    },
    codeScanner: {
        endpoint: 'https://localhost:8089/scanner/',
        username: 'scanner',
        password: 'scanner',
    },
}
